import type {
  CanselPhoneNumberReqType,
  GetActivationHistoryReqType, GetActivationHistoryResType,
  GetActivationStatusesDataType,
  GetActivationStatusesResType, GetPhoneNumberReqType, GetPhoneNumberResType
} from '~/composables/activation/type/ActivationTypes';
import type { ServerResponseCommonType } from '~/types/commons';

type useActivationServiceType = {
  getActivationStatuses: () => Promise<GetActivationStatusesDataType[] | undefined>;
  getActivationHistory: (options: GetActivationHistoryReqType) => Promise<GetActivationHistoryResType | undefined>;
  getPhoneNumber: (options: GetPhoneNumberReqType) => Promise<GetPhoneNumberResType | null>;
  canselPhoneNumber: (options: CanselPhoneNumberReqType) => Promise<ServerResponseCommonType>;
}

export const useActivationService = (): useActivationServiceType => {
  const { $api } = useNuxtApp();

  const getActivationStatuses = async (): Promise<GetActivationStatusesDataType[] | undefined> => {
    const resp = await $api<GetActivationStatusesResType>('activation/getActivationStatuses')
      .catch((err) => { throw err; });

    if (resp.status === 'ok') {
      return resp.data;
    }
  };

  const getActivationHistory = async (options: GetActivationHistoryReqType): Promise<GetActivationHistoryResType | undefined> => {
    const resp = await $api<ServerResponseCommonType<GetActivationHistoryResType>>('activation/getActivationHistory', {
      query: { ...options }
    }).catch((err) => { throw err; });

    if (resp.status === 'ok') {
      return resp.data;
    }
  };

  const getPhoneNumber = async (options: GetPhoneNumberReqType): Promise<GetPhoneNumberResType | null> => {
    const data = await $api<ServerResponseCommonType<GetPhoneNumberResType>>('api.php', {
      query: { ...options }
    }).catch((err) => { throw err; });

    if (data && data.status === 'ok') {
      return data.data;
    }

    throw data;
  };

  const canselPhoneNumber = async (options: CanselPhoneNumberReqType): Promise<ServerResponseCommonType> => {
    const data = await $api<ServerResponseCommonType>('api.php?method=cancel', {
      query: { ...options }
    }).catch((err) => { throw err; });

    if (data && data.status === 'ok') {
      return data;
    }

    throw data;
  };

  return {
    canselPhoneNumber,
    getPhoneNumber,
    getActivationHistory,
    getActivationStatuses
  };
};
